<script>
  import {
    hovered,
    glide,
    thereminPos,
    thereminMobilePos,
    canvasMousePos,
    mousePos,
    poseNetRes,
    handPoseRes,
    CANVASWIDTH,
    WIDTH,
    CANVASHEIGHT,
    videoReady,
    SCALE,
    dragged,
    audioControls,
    mouseOverride,
    gestures,
    manual,
    showGuides,
  } from "../../stores.js";
  import { constrain,getTween } from "../../helpers.js";
  import { fade,fly } from 'svelte/transition';
  import { cubicInOut } from 'svelte/easing';
  import { tweened, spring } from "svelte/motion";
  import { backOut } from "svelte/easing";
  import { posenetOptions } from "../../config.js";
  import { get } from "svelte/store";


  export let stage = null;
  export let app = null;
  export let createGradientTexture = null;

  //Initialize Mouse Pos
  canvasMousePos.set({
    x:
      $WIDTH > 600
        ? $thereminPos.x + $thereminPos.width * 0.12
        : $thereminPos.x + $thereminPos.width * 0.12,
    y:
      $WIDTH > 600
        ? $thereminPos.y + $thereminPos.height * 0.7
        : $thereminPos.y + $thereminPos.height * 0.7,
  });

  const cursorLight = new PIXI.lights.PointLight(0xff7f00, 2);
  cursorLight.falloff = [0.75, 3, 10];

  const gradientTexture = createGradientTexture(0, 12, $SCALE);
  const gradientTexture2 = createGradientTexture(0, 24, $SCALE);

  const particleContainer = new PIXI.particles.ParticleContainer(2000, {
    scale: true,
    position: true,
    rotation: false,
    uvs: false,
    tint: true,
  });
  const emitter = new PIXI.particles.Emitter(
    particleContainer,
    [gradientTexture],
    {
      autoUpdate: true,
      emit: false,
      alpha: {
        start: 0.8,
        end: 0.1,
      },
      scale: {
        start: 1,
        end: 0.2,
        minimumScaleMultiplier: 1,
      },
      color: {
        start: "#FFFF33",
        end: "#ff7f00",
      },
      speed: {
        start: 0,
        end: 0,
        minimumSpeedMultiplier: 1,
      },
      acceleration: {
        x: 0,
        y: 0,
      },
      maxSpeed: 0,
      startRotation: {
        min: 0,
        max: 0,
      },
      noRotation: true,
      rotationSpeed: {
        min: 0,
        max: 0,
      },
      lifetime: {
        min: 0.3,
        max: 0.3,
      },
      blendMode: "normal",
      frequency: 0.0008,
      emitterLifetime: -1,
      maxParticles: 400,
      pos: { x: 0, y: 0 },
      addAtBack: false,
      spawnType: "point",
    }
  );

  const emitterRight = new PIXI.particles.Emitter(
    particleContainer,
    [gradientTexture2],
    {
      autoUpdate: true,
      emit: false,
      alpha: {
        start: 0.8,
        end: 0.15,
      },
      scale: {
        start: 1,
        end: 0.2,
        minimumScaleMultiplier: 1,
      },
      color: {
        start: "#FFFF33",
        end: "#8D1A6D",
      },
      speed: {
        start: 0,
        end: 0,
        minimumSpeedMultiplier: 1,
      },
      acceleration: {
        x: 0,
        y: 0,
      },
      maxSpeed: 0,
      startRotation: {
        min: 0,
        max: 0,
      },
      noRotation: true,
      rotationSpeed: {
        min: 0,
        max: 0,
      },
      lifetime: {
        min: 0.3,
        max: 0.3,
      },
      blendMode: "normal",
      frequency: 0.0008,
      emitterLifetime: -1,
      maxParticles: 400,
      pos: { x: 0, y: 0 },
      addAtBack: false,
      spawnType: "point",
    }
  );

  const emitterLeft = new PIXI.particles.Emitter(
    particleContainer,
    [gradientTexture2],
    {
      autoUpdate: true,
      emit: false,
      alpha: {
        start: 0.8,
        end: 0.15,
      },
      scale: {
        start: 1,
        end: 0.2,
        minimumScaleMultiplier: 1,
      },
      color: {
        start: "#FFFF33",
        end: "#8D1A6D",
      },
      speed: {
        start: 0,
        end: 0,
        minimumSpeedMultiplier: 1,
      },
      acceleration: {
        x: 0,
        y: 0,
      },
      maxSpeed: 0,
      startRotation: {
        min: 0,
        max: 0,
      },
      noRotation: true,
      rotationSpeed: {
        min: 0,
        max: 0,
      },
      lifetime: {
        min: 0.3,
        max: 0.3,
      },
      blendMode: "normal",
      frequency: 0.0008,
      emitterLifetime: -1,
      maxParticles: 400,
      pos: { x: 0, y: 0 },
      addAtBack: false,
      spawnType: "point",
    }
  );

  let springLeftPos = spring(
    {
      x:
        $WIDTH > 600
          ? $thereminPos.x + $thereminPos.width * 0.12
          : $CANVASWIDTH * 0.7,
      y:
        $WIDTH > 600
          ? $thereminPos.y + $thereminPos.height * 0.7
          : $CANVASHEIGHT * 0.1,
    },
    {
      stiffness: 0.05,
      damping: 0.9
    }
  );

  let springRightPos = spring(
    {
      x: $thereminPos.x + $thereminPos.width * 0.88,
      y: $thereminPos.y + $thereminPos.height * 0.7,
    },
    {
      stiffness: 0.05,
      damping: 0.9
    }
  );

  let gesturesCounter = 0;
  let leftCounter = 0;
  let rightCounter = 0;

  stage.addChild(cursorLight, particleContainer);
  
  
  
  
    $: {
      
    //POSENET CURSOR//
    if ($poseNetRes && $videoReady && $mouseOverride > 0.1) {
      
      let wrist ={
        left:{
          pose: $poseNetRes[9],
          x: 0,
          y: 0,
        },
        right:{
          pose: $poseNetRes[10],
          x: 0,
          y: 0,
        }
      }
     
      emitter.emit = false;
      gestures.set(true);

      if (wrist.right.pose.score > posenetOptions.minPartConfidence) {
        if ($WIDTH > 600) {
          //Don't Interpolate on mobile
          springRightPos.set(wrist.right.pose.position);
        }
        wrist.right.x =
          $WIDTH > 600
            ? ($springRightPos.x / $videoReady.videoWidth) * $CANVASWIDTH
            : (wrist.right.pose.position.x / $videoReady.videoWidth) *
              $CANVASWIDTH;
        wrist.right.y =
          $WIDTH > 600
            ? ($springRightPos.y / $videoReady.videoHeight) * $CANVASHEIGHT
            : (wrist.right.pose.position.y / $videoReady.videoHeight) *
              $thereminMobilePos.y;
        wrist.right.audioX = constrain(
          (wrist.right.x - $thereminPos.x) / $thereminPos.width,
          { min: 0, max: 1 }
        );
        wrist.right.audioY = constrain(
          wrist.right.y / ($thereminPos.y + $thereminPos.height),
          { min: 0, max: 1 }
        );
        emitterRight.updateOwnerPos(wrist.right.x, wrist.right.y);
      }

      if (wrist.left.pose.score > posenetOptions.minPartConfidence) {
        if ($WIDTH > 600) {
          //Don't Interpolate on mobile
          springLeftPos.set(wrist.left.pose.position);
        }
        wrist.left.x =
          $WIDTH > 600
            ? ($springLeftPos.x / $videoReady.videoWidth) * $CANVASWIDTH
            : (wrist.left.pose.position.x / $videoReady.videoWidth) *
              $CANVASWIDTH;
        wrist.left.y =
          $WIDTH > 600
            ? ($springLeftPos.y / $videoReady.videoHeight) * $CANVASHEIGHT
            : (wrist.left.pose.position.y / $videoReady.videoHeight) *
              $thereminMobilePos.y;
        wrist.left.audioX = constrain(
          (wrist.left.x - $thereminPos.x) / $thereminPos.width,
          { min: 0, max: 1 }
        );
        wrist.left.audioY = constrain(
          wrist.left.y / ($thereminPos.y + $thereminPos.height),
          { min: 0, max: 1 }
        );
        emitterLeft.updateOwnerPos(wrist.left.x, wrist.left.y);
      }

      if (
        wrist.left.pose.score > posenetOptions.minPartConfidence &&
        wrist.right.pose.score > posenetOptions.minPartConfidence
      ) {
        //both hands
        emitterRight.emit = true;
        emitterLeft.emit = true;
        audioControls.set({
          x: wrist.right.audioX,
          y: wrist.left.audioY,
        });
        cursorLight.position.set(wrist.right.x,wrist.left.y);
        gesturesCounter += gesturesCounter < 1 ? 0.01 : 0;
      } else if (wrist.left.pose.score > wrist.right.pose.score) {
        //left hand
        emitterRight.emit = false;
        if (wrist.left.pose.score > posenetOptions.minPartConfidence) {
          emitterLeft.emit = true;
          audioControls.set({
            x: wrist.left.audioX,
            y: wrist.left.audioY,
          });
          cursorLight.position.set(wrist.left.x,wrist.left.y);
          gesturesCounter += gesturesCounter < 1 ? 0.01 : 0;
        }
      } else if (wrist.left.pose.score < wrist.right.pose.score) {
        //right hand
        if (wrist.right.pose.score > posenetOptions.minPartConfidence) {
          emitterRight.emit = true;
          audioControls.set({
            x: wrist.right.audioX,
            y: wrist.right.audioY,
          });
          cursorLight.position.set(wrist.right.x,wrist.right.y);
          gesturesCounter += gesturesCounter < 1 ? 0.01 : 0;
        }
        emitterLeft.emit = false;
      } else {
        //no hands
        emitterRight.emit = false;
        emitterLeft.emit = false;
        gesturesCounter -= gesturesCounter >= 0 ? 0.01 : 0;
      }
    }
      
    //MEDIAPIPE HANDS CURSOR//  
    else if ($handPoseRes && $videoReady && $mouseOverride > 0.1){
      const pose = $handPoseRes
      const poselm = pose.poseLandmarks || null;
      const handlm = {
        left: pose.leftHandLandmarks || null,
        right: pose.rightHandLandmarks || null
      };
      
        let wrist ={
        left:{
          x: 0,
          y: 0,
        },
        right:{
          x: 0,
          y: 0,
        }
      }
        
      emitter.emit = false;
      gestures.set(true);
      
      //start inactivity timer to detect 1 hand usage
      leftCounter += leftCounter < 1 ? 0.01 : 0;
      rightCounter += rightCounter < 1 ? 0.01 : 0;

      if(handlm.right){
        let maxX = 0
        let maxWeight = 0
        let minX = 1
        let maxY = 1;
        rightCounter = 0 //Reset Timer for when detected
      
        //find point closest to the right of the video frame
        let points = [8,12,16,20]
        points.forEach(e=>{
          let point = handlm.right[e]
          maxX = Math.max(1-point.x,maxX)
          minX = Math.min(1-point.x,minX)
          if(point.x>handlm.right[0].x){
            maxWeight+=1
          }
          maxY = Math.min(point.y,maxY)
        })
   
        let tweenX = getTween(minX,maxX,maxWeight/4)
        springRightPos.set({
          x:tweenX,
          y:maxY});
        
      }else if(poselm && rightCounter>.1){
         springRightPos.set({
        x:(1-poselm[16].x),
        y:poselm[16].y}); 
      }
      
      if(handlm.left){
        let maxX = 0;
        let minX = 1;
        let maxWeight = 0
        let maxY = 1;
        leftCounter = 0 //Reset Timer for when detected
        
        let points = [8,12,16,20]
        points.forEach(e=>{
          let point = handlm.left[e]
          maxX = Math.max(1-point.x,maxX)
          minX = Math.min(1-point.x,minX)
          if(point.x>handlm.left[0].x){
            maxWeight+=1
          }
          maxY = Math.min(point.y,maxY)
        })
   
        let tweenX = getTween(minX,maxX,maxWeight/4)    
        springLeftPos.set({
          x:tweenX,
          y:maxY});
        
      }else if(poselm && leftCounter>.1){
        springLeftPos.set({
          x:(1-poselm[15].x),
          y:poselm[15].y});
      }
      
      
      if(poselm){
        wrist.right.x = $springRightPos.x * $CANVASWIDTH
        wrist.right.y =
          $WIDTH > 600
            ? $springRightPos.y  * $CANVASHEIGHT
            : $springRightPos.y * $thereminMobilePos.y;
        wrist.right.audioX = constrain(
          (wrist.right.x - $thereminPos.x) / $thereminPos.width,
          { min: 0, max: 1 }
        );
        wrist.right.audioY = constrain(
          wrist.right.y / ($thereminPos.y + $thereminPos.height),
          { min: 0, max: 1 }
        );
        wrist.left.x = $springLeftPos.x * $CANVASWIDTH
        wrist.left.y =
          $WIDTH > 600
            ? $springLeftPos.y* $CANVASHEIGHT
            : $springLeftPos.y * $thereminMobilePos.y;
        wrist.left.audioX = constrain(
          (wrist.left.x - $thereminPos.x) / $thereminPos.width,
          { min: 0, max: 1 }
        );
        wrist.left.audioY = constrain(
          wrist.left.y / ($thereminPos.y + $thereminPos.height),
          { min: 0, max: 1 }
        );   

        //update audio
        if(leftCounter>=.5 && rightCounter>=.5){
          emitterRight.emit = false;
          emitterLeft.emit = false;
        }
        if(leftCounter >= .5){
          //set right hand if left hand is inactive
          audioControls.set({
            x: wrist.right.audioX,
            y: wrist.right.audioY,
          });
          cursorLight.position.set(wrist.right.x,wrist.right.y);
          emitterRight.updateOwnerPos(wrist.right.x, wrist.right.y);
          emitterRight.emit = true;
          emitterLeft.emit = false;
        }else if (rightCounter >= .5){
          //set left hand if right hand is inactive
          audioControls.set({
            x: wrist.left.audioX,
            y: wrist.left.audioY,
          });
          cursorLight.position.set(wrist.left.x,wrist.left.y);
          emitterLeft.updateOwnerPos(wrist.left.x, wrist.left.y);
          emitterRight.emit = false;
          emitterLeft.emit = true;
        }else{
          //both hands
          audioControls.set({
            x: wrist.right.audioX,
            y: wrist.left.audioY,
          });
          cursorLight.position.set(wrist.right.x,wrist.left.y);
    
          emitterRight.updateOwnerPos(wrist.right.x, wrist.right.y);
          emitterLeft.updateOwnerPos(wrist.left.x, wrist.left.y);
          emitterRight.emit = true;
          emitterLeft.emit = true;
        }
      }
      
      if(handlm.right || handlm.left){
        gesturesCounter += gesturesCounter < 1 ? 0.01 : 0;
      }
    }
      
    //MOUSE CURSOR//
    else {
      //cursor 
      emitter.emit = true;
      emitterRight.emit = false;
      emitterLeft.emit = false;
      gestures.set(false);

      cursorLight.brightness = 2 * constrain(2 - $SCALE, { max: 1, min: 0.5 });
      if (($canvasMousePos.y > $thereminMobilePos.y && $WIDTH < 600) || $dragged) {
        
      }else{
        emitter.updateOwnerPos($canvasMousePos.x, $canvasMousePos.y);
        cursorLight.position.set($canvasMousePos.x, $canvasMousePos.y);
        audioControls.set({
          x: constrain(
            ($canvasMousePos.x - $thereminPos.x) / $thereminPos.width,
            { min: 0, max: 1 }
          ),
          y: constrain(
            $canvasMousePos.y / ($thereminPos.y + $thereminPos.height),
            { min: 0, max: 1 }
          ),
        });

      }
    } 

    if ($manual) {
      emitter.emit = false;
      emitterRight.emit = false;
      emitterLeft.emit = false;
    }
  }


  $: {
    if ($showGuides && gesturesCounter > 1) {
      showGuides.set(false);
    }
  }
</script>

{#if $hovered && $hovered !=="video"}
<container
  style="opacity:{mousePos ? 1 : 0};
  transform:translate({$mousePos.x - 26}px, {$mousePos.y - 26}px)"
>
  <div>
    <span
    >{$hovered === 'switch' && !$glide ? 'Enable Glide (G)' : $hovered === 'switch' && $glide ? 'Disable Glide (G)' : $hovered === 'knob left' ? 'Adjust Volume' : $hovered === 'knob right' ? 'Change Oscillator (O)' : ''}</span>
  </div>
</container>
{/if}

<style>
  container {
    top: 0;
    left: 0;
    position: fixed;
    width: 48px;
    height: 48px;
    z-index: 2;
    pointer-events: none;
  }
  div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: center;
    border: 2px transparent;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    position: absolute;
    top: -32px;
    width: max-content;
    line-break: normal;
    font-size: 14px;
    font-family: "Nicholson Beta";
    background: rgb(var(--offwhite));
    padding: 6px 12px 6px 12px;
    border-radius: 16px;
    color: black;
/*     opacity: 0; */
    text-align: center;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    transition: 0s;
    display: flex;
    justify-content: center;
  }
  span:before {
    content: "";
    position: absolute;
    bottom: -6px;
    margin: auto;
    pointer-events: none;
    border-style: solid;
    border-width: 6px 4px 0px 4px;
    border-color: rgb(var(--offwhite)) transparent transparent transparent;
  }
/*   .hovered span {
    opacity: 1;
  } */
/*   @media (hover: none) {
    div {
      opacity: 0;
    }
  } */
</style>
