<script>
  export let color = "var(--offwhite)";
  export let hoverColor = "var(--offwhite)";
</script>

  <svg width="15" height="23" viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9399 13.3756C14.0513 13.1246 14.0095 12.9015 13.8006 12.6923L2.07252 0.907967C1.84966 0.670886 1.59894 0.615101 1.36215 0.712722C1.12536 0.810344 1 1.03348 1 1.35424V17.7825C1 18.0615 1.11143 18.2707 1.34822 18.3962C1.58501 18.5217 1.80787 18.4798 2.04466 18.2707L5.27615 15.3141L8.50763 21.9384C8.60513 22.1197 8.7305 22.2452 8.89764 22.301C9.06479 22.3568 9.21801 22.3429 9.38515 22.2452L11.2238 21.3806C11.4188 21.283 11.5441 21.1435 11.6277 20.9762C11.7113 20.8088 11.7113 20.6415 11.6138 20.4741L8.43799 13.8498L13.3131 13.7382C13.6195 13.7382 13.8284 13.6267 13.9399 13.3756Z" style="--color:{color};--hoverColor:{hoverColor}"/>
    </svg>
    

<style>
  svg {
    height: 100%;
    width: auto;
  }
  path {
    --color: var(--offwhite);
    --hoverColor: var(--charcoal);
    fill: rgb(var(--color));
  }

  svg:hover path {
    fill: rgb(var(--hoverColor));
  }
</style>



  