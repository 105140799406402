<script>
  export let color = "rgb(var(--crimson))";
  export let bgColor = "rgb(var(--offwhite))";
</script>

<svg width="622" height="635" viewBox="0 0 622 635" fill="none" xmlns="http://www.w3.org/2000/svg">
<!-- <path d="M2 67.9714C2 38.7161 25.7161 15 54.9714 15H567.029C596.284 15 620 38.7161 620 67.9714V580.029C620 609.284 596.284 633 567.029 633H54.9714C25.7161 633 2 609.284 2 580.029V67.9714Z" stroke="{color}" stroke-width="3" stroke-miterlimit="10"/> -->
<circle cx="200.559" cy="117.692" r="18.1286" transform="rotate(2.07073 200.559 117.692)" fill="{color}"/>
<circle cx="216.148" cy="177.418" r="8.99268" transform="rotate(2.07073 216.148 177.418)" fill="{color}"/>
<circle cx="238.525" cy="240.347" r="8.99268" transform="rotate(2.07073 238.525 240.347)" fill="{color}"/>
<circle cx="253.723" cy="310.905" r="8.99268" transform="rotate(2.07073 253.723 310.905)" fill="{color}"/>
<circle cx="325.666" cy="584.664" r="8.99268" transform="rotate(2.07073 325.666 584.664)" fill="{color}"/>
<circle cx="228.343" cy="521.984" r="8.99268" transform="rotate(2.07073 228.343 521.984)" fill="{color}"/>
<circle cx="180.091" cy="438.398" r="8.99268" transform="rotate(2.07073 180.091 438.398)" fill="{color}"/>
<circle cx="138.198" cy="369.834" r="8.99268" transform="rotate(2.07073 138.198 369.834)" fill="{color}"/>
<circle cx="78.2951" cy="308.506" r="18.0063" transform="rotate(2.07073 78.2951 308.506)" fill="{color}"/>
<path d="M82.353 308.528L135.275 365.42L179.495 437.76L229.189 522.383L327.443 586.577M327.443 586.577L254.059 311.779M327.443 586.577L437.779 371.174M200.44 117.564L216.03 177.289L238.933 239.251L254.059 311.779M254.059 311.779L325.228 306.957M335.648 86.9552L330.782 153.336L328.13 226.7L325.228 306.957M325.228 306.957L378.659 329.102M419.546 125.486L409.775 191.197L397.578 255.834L378.659 329.102M378.659 329.102L437.779 371.174M551.097 250.538L516.767 300.078L485.1 330.486L437.779 371.174" stroke="{color}" stroke-width="5.91233" stroke-linecap="round"/>
<circle cx="324.873" cy="306.575" r="8.99268" transform="rotate(2.07073 324.873 306.575)" fill="{color}"/>
<circle cx="377.338" cy="328.192" r="8.99268" transform="rotate(2.07073 377.338 328.192)" fill="{color}"/>
<circle cx="436.931" cy="370.774" r="8.99268" transform="rotate(2.07073 436.931 370.774)" fill="{color}"/>
<circle cx="483.761" cy="330.068" r="8.99268" transform="rotate(2.07073 483.761 330.068)" fill="{color}"/>
<circle cx="515.464" cy="298.676" r="8.99268" transform="rotate(2.07073 515.464 298.676)" fill="{color}"/>
<circle cx="550.689" cy="251.634" r="18.2691" transform="rotate(2.07073 550.689 251.634)" fill="{color}"/>
<circle cx="397.734" cy="254.977" r="8.99268" transform="rotate(2.07073 397.734 254.977)" fill="{color}"/>
<circle cx="327.793" cy="225.826" r="8.99268" transform="rotate(2.07073 327.793 225.826)" fill="{color}"/>
<circle cx="330.429" cy="152.955" r="8.99268" transform="rotate(2.07073 330.429 152.955)" fill="{color}"/>
<circle cx="334.747" cy="88.0331" r="18.4452" transform="rotate(2.07073 334.747 88.0331)" fill="{color}"/>
<circle cx="418.191" cy="125.561" r="18.28" transform="rotate(2.07073 418.191 125.561)" fill="{color}"/>
<circle cx="408.945" cy="190.305" r="8.99268" transform="rotate(2.07073 408.945 190.305)" fill="{color}"/>
</svg>




<style>
  svg {
    width: 100%;
    height: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    overflow: hidden;
  }
</style>
