<script>
  export let color = "rgb(var(--crimson))";
</script>

<svg
  width="46"
  height="46"
  viewBox="0 0 46 46"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="M10 23L30.25 1.34936L30.25 44.6506L10 23Z" fill="{color}"></path>
</svg>

<style>
  svg {
    height: 100%;
    width: 50%;
  }
  /* :hover path{
    fill:rgb(var(--crimson))
} */
</style>
