<script>
  export let color = "var(--offwhite)";
  export let hoverColor = "var(--offwhite)";
</script>

<svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.5996 17.4895C17.1512 18.0871 16.8115 18.6135 16.6213 19.0831C16.4175 19.5526 16.3224 20.2356 16.3224 21.1036C16.3224 21.3455 16.2408 21.5447 16.0642 21.7297C15.8876 21.9146 15.6973 22 15.4663 22L8.12906 21.9715C7.89807 21.9715 7.70784 21.8719 7.5312 21.687C7.36815 21.502 7.27304 21.2743 7.27304 21.004V20.2925C7.27304 20.008 7.12358 19.7518 6.83824 19.51C4.84087 17.7029 3.1696 15.5828 1.81084 13.1354L1.26734 12.0683C0.927646 11.428 0.914056 10.8304 1.21298 10.2754C1.51191 9.72052 1.98748 9.39326 2.62609 9.29366C3.26471 9.19406 3.8218 9.42172 4.28377 9.97664L5.47948 11.3711C5.54742 11.4707 5.61536 11.4991 5.69688 11.4849C5.77841 11.4564 5.81917 11.3995 5.81917 11.3142L5.11262 2.7484C5.04468 2.0085 5.27567 1.49626 5.80558 1.21169C6.3355 0.927109 6.89259 0.92711 7.46326 1.22592C8.03394 1.52472 8.36005 2.03696 8.42798 2.77686L8.97149 9.17983C8.97149 9.29366 9.03942 9.36481 9.16171 9.37903C9.284 9.39326 9.35194 9.35057 9.35194 9.25097L9.21606 7.71426C9.1753 7.24471 9.40629 6.90322 9.92262 6.71824C10.4389 6.53327 10.9824 6.53327 11.526 6.71824C12.0695 6.90322 12.3412 7.24471 12.3412 7.71426V9.20829C12.3412 9.30789 12.4091 9.35057 12.545 9.35057C12.6809 9.35057 12.7488 9.30789 12.7488 9.20829V7.7854C12.8439 7.25894 13.1565 6.90322 13.7135 6.70401C14.2706 6.50481 14.8006 6.51904 15.3305 6.76093C15.8468 7.00282 16.1185 7.41546 16.1185 8.01307V9.22252C16.1185 9.33635 16.1729 9.39326 16.2816 9.39326C16.3903 9.39326 16.4446 9.33635 16.4446 9.22252C16.4446 8.61068 16.6756 8.19804 17.124 7.97038C17.5724 7.75695 18.0344 7.79963 18.4828 8.08421C18.9312 8.36879 19.1622 8.82411 19.1622 9.47864V13.5908C19.1622 13.9892 19.1486 14.3307 19.1078 14.5868C19.0806 14.8429 18.9991 15.1133 18.8904 15.3694C18.6594 16.0239 18.2382 16.7353 17.5996 17.4895Z" style="--color:{color};--hoverColor:{hoverColor}"/>
  </svg>

<style>
  svg {
    height: 100%;
    width: auto;
  }
  path {
    --color: var(--offwhite);
    --hoverColor: var(--charcoal);
    fill: rgb(var(--color));
  }

  svg:hover path {
    fill: rgb(var(--hoverColor));
  }
</style>



  