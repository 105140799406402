<script>
  export let color = "rgb(var(--crimson))";
  export let bgColor = "rgb(var(--offwhite))";
</script>

<svg width="622" height="622" viewBox="0 0 622 622" fill="none" xmlns="http://www.w3.org/2000/svg">
<!-- <path d="M2 54.9714C2 25.7161 25.7161 2 54.9714 2H567.029C596.284 2 620 25.7161 620 54.9714V567.029C620 596.284 596.284 620 567.029 620H54.9714C25.7161 620 2 596.284 2 567.029V54.9714Z" stroke="{color}" stroke-width="3" stroke-miterlimit="10"/> -->
<ellipse cx="301.473" cy="108.044" rx="49.4727" ry="64.8428" transform="rotate(-6.52388 301.473 108.044)" fill="{color}"/>
<path d="M227 577.33L227 538.33L247 419M247 419L210.5 199M247 419L377 419M210.5 199L140 291L101 367.5M210.5 199L409.5 199M409.5 199L377 419M409.5 199L477 285L533 204.5M377 419L393.5 541.5L393.5 577.33" stroke="{color}" stroke-width="6" stroke-linecap="round"/>
<circle cx="209.2" cy="200.265" r="9.12603" fill="{color}"/>
<circle cx="139.126" cy="291.525" r="9.12603" fill="{color}"/>
<circle cx="99.4962" cy="368.243" r="23.9962" fill="{color}" stroke="{color}"/>
<circle cx="533.496" cy="202.826" r="24.4962" fill="{color}"/>
<circle cx="410.024" cy="200.265" r="9.12603" fill="{color}"/>
<circle cx="477.126" cy="285.456" r="9.12603" fill="{color}"/>
<circle cx="374.482" cy="418.329" r="9.12603" fill="{color}"/>
<circle cx="247.347" cy="419.29" r="9.12603" fill="{color}"/>
<circle cx="226.437" cy="541.576" r="9.12603" fill="{color}"/>
<circle cx="393.126" cy="540.456" r="9.12603" fill="{color}"/>
</svg>



<style>
  svg {
    width: 100%;
    height: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    overflow: hidden;
  }
</style>
