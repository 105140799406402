<script>
 export let color = "var(--offwhite)";
  export let hoverColor = "var(--charcoal)";
  export let selected = false;
</script>

<svg class={selected ? 'selected' : ''} width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="arrow" d="M28.8916 35.1379C29.359 35.1379 29.5928 34.9041 29.5928 34.4366V31.4915C29.5928 31.0707 29.7564 30.7903 30.0836 30.65C30.4109 30.463 30.7147 30.463 30.9952 30.65C31.3225 30.7903 31.4861 31.0707 31.4861 31.4915V36.33C31.4861 36.7974 31.2524 37.0312 30.7849 37.0312H15.4981C15.0306 37.0312 14.7969 36.7974 14.7969 36.33V31.4915C14.7969 31.0707 14.9605 30.7903 15.2877 30.65C15.615 30.463 15.9188 30.463 16.1993 30.65C16.5266 30.7903 16.6902 31.0707 16.6902 31.4915V34.4366C16.6902 34.9041 16.9239 35.1379 17.3914 35.1379H28.8916Z" fill="{color}"  style="--color:{color};--hoverColor:{hoverColor}"/>
<path class="drawer" d="M23.0691 32.1924C22.3679 32.1924 21.7836 31.982 21.3161 31.5613C20.8953 31.1405 20.7083 30.5562 20.7551 29.8082L21.1057 13.6799C21.1525 13.0255 21.3862 12.4879 21.8069 12.0671C22.2277 11.6464 22.7419 11.436 23.3496 11.436C23.9574 11.436 24.4482 11.6464 24.8222 12.0671C25.2429 12.4879 25.4767 13.0255 25.5234 13.6799L25.8741 29.8082C25.9208 30.5562 25.7104 31.1405 25.2429 31.5613C24.8222 31.982 24.2612 32.1924 23.56 32.1924H23.0691ZM13.9532 19.2898C13.4857 18.7755 13.252 18.2379 13.252 17.6769C13.2987 17.0692 13.5558 16.5316 14.0233 16.0641L21.7368 8.70123C22.2043 8.23374 22.7185 8 23.2795 8C23.8405 8 24.3547 8.23374 24.8222 8.70123L32.5357 16.0641C33.05 16.5784 33.3071 17.116 33.3071 17.6769C33.3538 18.2379 33.1435 18.7522 32.676 19.2196C32.2552 19.6871 31.7176 19.8975 31.0631 19.8508C30.4554 19.804 29.9178 19.5235 29.4503 19.0093L25.8741 15.0824C25.1728 14.2877 24.308 13.8903 23.2795 13.8903C22.251 13.8903 21.3862 14.2877 20.685 15.0824L17.1788 18.9392C16.7113 19.4534 16.1504 19.7573 15.4959 19.8508C14.8882 19.8975 14.3739 19.7105 13.9532 19.2898Z" fill="{color}"  style="--color:{color};--hoverColor:{hoverColor}"/>
</svg>

      
<style>
  svg {
    height: auto;
    width: 100%;
  }
  path {
    --color: var(--offwhite);
    --hoverColor: var(--charcoal);
    fill: rgb(var(--color));
  }

  svg:hover path {
    fill: rgb(var(--hoverColor));
  }

  g{
    transform-origin:center;
    transform-box: fill-box;
  }
  svg.selected g{
    animation: rotate 2s linear infinite;
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
