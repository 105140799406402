<script>
  export let color = "var(--offwhite)";
  export let hoverColor = "var(--charcoal)";
</script>

<svg
  class="settingsSVG"
  width="46"
  height="46"
  viewBox="0 0 46 46"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M31.4152 24.6212C31.4576 24.2619 31.4859 23.8881 31.4859 23.5C31.4859 23.1119 31.4576 22.7381 31.4011 22.3788L33.7913 20.4813C34.0034 20.3088 34.06 19.9925 33.9327 19.7481L31.6698 15.7662C31.5283 15.5075 31.2313 15.4213 30.9768 15.5075L28.1623 16.6575C27.5683 16.1975 26.946 15.8238 26.2529 15.5363L25.8286 12.4887C25.7862 12.2012 25.5458 12 25.2629 12H20.7371C20.4542 12 20.2279 12.2012 20.1855 12.4887L19.7612 15.5363C19.0682 15.8238 18.4318 16.2119 17.8519 16.6575L15.0374 15.5075C14.7828 15.4069 14.4858 15.5075 14.3444 15.7662L12.0815 19.7481C11.94 20.0069 11.9966 20.3088 12.2229 20.4813L14.6131 22.3788C14.5565 22.7381 14.5141 23.1263 14.5141 23.5C14.5141 23.8737 14.5424 24.2619 14.5989 24.6212L12.2087 26.5188C11.9966 26.6913 11.94 27.0075 12.0673 27.2519L14.3302 31.2338C14.4717 31.4925 14.7687 31.5788 15.0232 31.4925L17.8377 30.3425C18.4318 30.8025 19.0541 31.1762 19.7471 31.4637L20.1714 34.5112C20.2279 34.7987 20.4542 35 20.7371 35H25.2629C25.5458 35 25.7862 34.7987 25.8145 34.5112L26.2388 31.4637C26.9318 31.1762 27.5683 30.7881 28.1481 30.3425L30.9626 31.4925C31.2172 31.5931 31.5142 31.4925 31.6556 31.2338L33.9185 27.2519C34.06 26.9931 34.0034 26.6913 33.7771 26.5188L31.4152 24.6212V24.6212ZM23 27.8125C20.6664 27.8125 18.757 25.8719 18.757 23.5C18.757 21.1281 20.6664 19.1875 23 19.1875C25.3336 19.1875 27.243 21.1281 27.243 23.5C27.243 25.8719 25.3336 27.8125 23 27.8125Z"
    style="--color:{color};--hoverColor:{hoverColor}"
  ></path>
</svg>

<style>
  svg {
    height: auto;
    width: 100%;
    pointer-events: none;
  }
  path {
    --color: var(--offwhite);
    --hoverColor: var(--charcoal);
    fill: rgb(var(--svgColor));
  }

  :global(button:hover .settingsSVG path) {
    fill: rgb(var(--svgColorHover));
  }
</style>
